import React, { useState, useEffect } from 'react';
import Name from './columns/Name';
import { NameWithAvatar } from './columns/NameWithAvatar';
import Pill from './columns/Pill';
import Status from './columns/Status';
import { StringWithTooltips } from './columns/StringWithTooltips';
import Button from '../../../components/bootstrap/Button';
import { IVisit } from '../../api/interface/visit.interface';
import useDarkMode from '../../../hooks/useDarkMode';
import { Sum } from './columns/Sum';
import DeleteOpen from './columns/DeleteOpen';
import { formatDate, getFontSizeFromLocalStorage } from '../../../utils/functions';
import Icon from '../../../components/icon/Icon';
import { medicalCardPages } from '../../../menu';
import VisitDropdownActions from '../../pages/visit/VisistPageComponents/Modals/VisitDropdownActions';
import { fetchCategory } from '../../api/direct_call';

interface ITableProps {
	headers: any[];
	data: any[];
	visitDropDownProps: any;
}
const compareData = (current: any, previous: any) => {
	return JSON.stringify(current) === JSON.stringify(previous);
};
function getNestedValue(obj: any, path: any) {
	return path.split('.').reduce((acc: any, key: any) => acc && acc[key], obj);
}
const TableComponent: React.FC<ITableProps> = ({ headers, data, visitDropDownProps }) => {
	const { themeStatus } = useDarkMode();
	const getRootFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);
	const [dynamicFont, setDynamicFont] = useState(getRootFontSize());

	const updateFontSize = () => {
		const fontSizeValue = getFontSizeFromLocalStorage();
		setDynamicFont(fontSizeValue);
	};

	useEffect(() => {
		updateFontSize();

		const handleFontSizeChange = () => {
			updateFontSize();
		};

		window.addEventListener('fontSizeChange', handleFontSizeChange);

		return () => {
			window.removeEventListener('fontSizeChange', handleFontSizeChange);
		};
	}, []);
	const [tempData, setTempData] = useState(data);

	const [categoryData, setCategoryData] = useState<
		{
			isLoading: boolean;
			category: string | null;
			subCategory: string | null;
			error?: string;
		}[]
	>([]);
	const loadCategory = async (uuid: string, index: number) => {
		const result = await fetchCategory(uuid);
		setCategoryData((prev: any) => {
			const updated = [...prev];
			updated[index] = result;
			return updated;
		});
	};
	useEffect(() => {
		setTempData(data);
		const compared = compareData(data, tempData);
		if (headers.some((header) => header.key === 'categoryUuid' && data && compared)) {
			setCategoryData(
				new Array(tempData?.length).fill({
					isLoading: true,
					category: null,
					subCategory: null,
				}),
			);
			data?.forEach((item: any, index: number) => {
				loadCategory(item.categoryUuid, index);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, headers]);

	const renderCell = (key: string, item: any, index: number) => {
		switch (key) {
			case 'id':
				return (
					<Button
						onClick={() => {
							if (
								visitDropDownProps?.setCanvasData &&
								visitDropDownProps?.setEventAdding
							) {
								visitDropDownProps.setCanvasData(item);
								visitDropDownProps.setEventAdding(true);
							}
						}}
						className='fw-bold fs-5 border-0 p-0'>
						{item.visitId}
					</Button>
				);

			case 'id_patient':
				return (
					<Button
						className='fw-bold fs-5 border-0 p-0'
						tag='a'
						to={`../${medicalCardPages.customerID.path}/${
							item.uuid ? item.uuid : null
						}`}>
						{item.patientId}
					</Button>
				);
			case 'datePill':
				return <Pill visitDate={item.visitDate} status={item.status} />;
			case 'nameWithAvatar':
				return (
					<NameWithAvatar
						user={item.patient}
						patientUuid={item.patientUuid}
						dynamicFont={dynamicFont}
						themeStatus={themeStatus}
					/>
				);
			case 'patientNameWithAvatar':
				return (
					<NameWithAvatar
						user={item}
						patientUuid={item}
						dynamicFont={dynamicFont}
						themeStatus={themeStatus}
					/>
				);

			case 'patientNameTable':
				return (
					<NameWithAvatar
						user={item}
						patientUuid={item.uuid}
						dynamicFont={dynamicFont}
						themeStatus={themeStatus}
						setIsOpen={visitDropDownProps?.setParentModal}
					/>
				);
			case 'name':
				return <Name user={item.user} themeStatus={themeStatus} />;
			case 'string':
				return <StringWithTooltips string={item.complaint} />;
			case 'title_all':
				return <span>{item.title_all?.rus}</span>;
			case 'title_all_rus':
				return <span>{item?.title_all?.rus}</span>;

			case 'title_all_en':
				return <span>{item?.title_all?.en}</span>;
			case 'title_all_arm':
				return <span>{item?.title_all?.arm}</span>;
			case 'name_rus':
				return <span>{item?.name?.rus}</span>;
			case 'name_en':
				return <span>{item?.name?.en}</span>;
			case 'name_arm':
				return <span>{item?.name?.arm}</span>;
			case 'status':
				return <Status status={item.status} statusName={item.status} />;
			case 'visitCountPatient':
				return <span className='fw-bold fs-5 text-center m-3'>{item?.visit?.length}</span>;
			case 'patientPhone':
				return <span className='fw-bold fs-5'>{item?.phone}</span>;
			case 'debtPatient':
				return <span className='fw-bold fs-5'>{item.debt ? item.debt : '0 '}</span>;
			case 'action':
				return (
					visitDropDownProps && (
						<div className='d-flex'>
							<VisitDropdownActions
								data={item}
								setEventAdding={visitDropDownProps?.setEventAdding}
								setCanvasData={visitDropDownProps?.setCanvasData}
								setUpdateVisitModal={visitDropDownProps?.setUpdateVisitModal}
								setActModal={visitDropDownProps?.setActModal}
								setActVisit={visitDropDownProps?.setActVisit}
								setCreateActModal={visitDropDownProps?.setCreateActModal}
								setPatientId={visitDropDownProps?.setPatientId}
								setMedicalCaseUuid={visitDropDownProps?.setMedicalCaseUuid}
								setServiceUuid={visitDropDownProps?.setServiceUuid}
								setClinicUuid={visitDropDownProps?.setClinicUuid}
								setStageUuid={visitDropDownProps?.setStageUuid}
								setVisitUpdate={visitDropDownProps?.setVisitUpdate}
								handleOpenCancel={visitDropDownProps?.handleOpenCancel}
								setVisitUuid={visitDropDownProps?.setVisitUuid}
								changeStatus={visitDropDownProps?.changeStatus}
								handeleSetParent={visitDropDownProps?.handeleSetParent}
								setParentModal={visitDropDownProps?.setParentModal}
								parentUuid={visitDropDownProps?.parentUuid}
							/>
						</div>
					)
				);
			case 'visitPill':
				return <Pill text={item?.visitNumber || '0000'} status={item.status} />;
			case 'sum':
				return (
					<Sum
						text={`${
							Number(
								item?.estimate?.act?.total_amount -
									item?.estimate?.act?.total_amount_for_pay,
							) || 0
						}`}
						status={item?.status}
					/>
				);
			case 'debt':
				return (
					<Sum
						debt
						text={`${Number(item?.estimate?.act?.total_amount_for_pay) || 0}`}
						status={item?.status}
					/>
				);
			case 'image':
				return (
					<img
						src={`https://backend.amracode.am${item.image}`}
						alt=''
						style={{
							objectFit: 'cover',
							width: '5.23rem',
							height: '2.8rem',
						}}
					/>
				);

			case 'patient_status':
				return (
					<div className='text-nowrap fw-bold'>
						{item?.status === 'active' ? (
							<>
								<Icon icon='Circle' className='me-2' color='info' />
								<span
									style={{
										color: '#0d6efd',
									}}>
									Активный
								</span>
							</>
						) : (
							<>
								<Icon icon='Circle' className='me-2' color='warning' />
								<span
									style={{
										color: '#FFCF52',
									}}>
									Неактивный
								</span>
							</>
						)}
					</div>
				);
			case 'index':
				return <p>{index + 1}</p>;
			case 'imageDate':
				return <p>{formatDate(new Date(item.imageDate)).date2}</p>;
			case 'deleteOpen':
				return <DeleteOpen image={item} />;
			case 'category':
				return <p>{item[key]?.name?.rus}</p>;
			case 'subCategoryUuid':
				return (
					<p>
						{categoryData && categoryData[index]?.isLoading
							? 'Loading...'
							: categoryData[index]?.subCategory || 'No Category'}
					</p>
				);
			case 'price':
				return <p>{`${item.price}${item?.currency ? ` ${item.currency}` : ''}`}</p>;
			case 'clinic':
				return <p>{`${item[key]?.name}`}</p>;
			case 'item_name':
				return <p>{`${item?.name}`}</p>;
			default:
				return <p>{getNestedValue(item, key)}</p>;
		}
	};
	const cols = (col: string) => {
		switch (col) {
			case 'id':
				return 1;
			case 'index':
				return 1;
			case 'action':
				return 1;
			case 'status':
				return 2;
			case 'datePill':
				return 2;
			case 'visitPill':
				return 2;
			case 'serviceId':
				return 1;
			case 'brand':
				return 1;
			case 'code':
				return 1;
			case 'clinic':
				return 1;
			case 'material_type':
				return 1;
			case 'category':
				return 1;
			case 'unit_measurment':
				return 1;
			case 'price':
				return 1;
			case 'store_type':
				return 1;
			case 'size':
				return 1;
			case 'debt':
				return 2;
			case 'sum':
				return 2;
			case 'estimate.id':
				return 1;
			default:
				return 2;
		}
	};
	return (
		<>
			{data?.length > 0 ? (
				<table className='table table-modern table-hover'>
					<thead>
						<tr>
							{headers.map((name, index) => (
								<th key={`${index}-${name.header}`}>{name.header}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data?.map((i: IVisit, index: number) => (
							<tr key={`${index}-${i.middleName}`}>
								{headers.map((name, idx) => (
									<td
										key={idx}
										style={{
											maxWidth: name.key === 'string' ? '25rem' : 'unset',
										}}
										className={`col-${cols(name.key)}`}>
										{renderCell(name?.key, i, index)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className='d-flex flex-column align-items-center justify-content-center w-100'>
					{/* <Icon icon='Search' size='3x' color='light' /> */}
					<h3>Нет подходящих данных</h3>
					{/* <p className='text-muted'>обновите фильтры и попробуйте снова</p> */}
				</div>
			)}
		</>
	);
};

export default TableComponent;
