import React, { SVGProps } from 'react';

const SvgTheethDirection = (props: SVGProps<SVGSVGElement>) => {
	return (	
		<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M7.3516 0.368126C7.15634 0.172864 6.83976 0.172864 6.64449 0.368126L3.46251 3.55011C3.26725 3.74537 3.26725 4.06195 3.46251 4.25721C3.65778 4.45248 3.97436 4.45248 4.16962 4.25721L6.99805 1.42879L9.82647 4.25721C10.0217 4.45248 10.3383 4.45248 10.5336 4.25721C10.7288 4.06195 10.7288 3.74537 10.5336 3.55011L7.3516 0.368126ZM7.49805 14.9092L7.49805 0.72168H6.49805L6.49805 14.9092H7.49805Z" fill="#0D6EFD"/>
			<path d="M10.9531 14.9092H3.04297C2.21454 14.9092 1.54297 14.2376 1.54297 13.4092L1.54297 9.31362C1.54297 8.48519 2.21454 7.81362 3.04297 7.81362L10.9531 7.81362C11.7816 7.81362 12.4531 8.48519 12.4531 9.31362V13.4092C12.4531 14.2376 11.7816 14.9092 10.9531 14.9092Z" fill="white" stroke="#0D6EFD" strokeWidth="1.5"/>
		</svg>
	);
};

export default SvgTheethDirection;
