import React, { FC, memo, useEffect, useState } from 'react';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import PaginationButtons, { PER_COUNT } from '../../../../../components/PaginationButtons';
import Button from '../../../../../components/bootstrap/Button';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { IUser } from '@textnpayme/custom/api/interface/user.interface';
import { UserService } from '../../../../../custom/api/service/user.service';
import { useDebounce } from '../../../../../hooks/useDebounce';
import UserImage3 from '../../../../../assets/img/wanna/wanna3.png';
import { Avatar } from 'antd';
import { validatePhoneNumber } from '../../../../../utils/functions';
import useDarkMode from '../../../../../hooks/useDarkMode';

interface IUserFilterComponentProps {
	handleCheckboxChange(...args: unknown[]): unknown;
	setPopperIsOpenUser(...args: unknown[]): unknown;
	popperIsOpenUser: any;
	values: any;
	dynamicFont: any;
	isUserDoctor: any;
	canvasColor: any;
}

const UserFilterComponent: FC<IUserFilterComponentProps> = memo(
	({
		values,
		setPopperIsOpenUser,
		popperIsOpenUser,
		handleCheckboxChange,
		isUserDoctor,
		dynamicFont,
		canvasColor,
	}) => {
		const DebounsedSearch = useDebounce(values);
		const [userParts, setUserParts] = useState<any>('');
		const [isValid, setIsValid] = useState<boolean>(false);
		const [currentPage, setCurrentPage] = useState<number>(1);
		const [perPage, setPerPage] = useState<number>(PER_COUNT['5']);
		const { themeStatus, darkModeStatus } = useDarkMode();

		useEffect(() => {
			if (values?.userName?.includes('+')) {
				setIsValid(validatePhoneNumber(values?.userName));
			}
			if (values.userName) {
				setUserParts(values?.userName.split(' '));
			}
		}, [values?.userName]);

		const { data: usersCount }: { data: IUser[] | any; isLoading: boolean } = useQuery(
			['usersCount', DebounsedSearch],
			() =>
				UserService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},

						includeMeta: [
							{
								association: 'roles',
								where: {
									role: ['mainDoctor', 'doctor'],
								},
								include: [
									{
										association: 'clinics',
									},
								],
							},
							{
								...(values.userName &&
									/\d/.test(values?.userName) &&
									!isValid && {
										association: 'passport',
										required: true,
										where: {
											or: [
												{
													passportNumber: {
														iLike: `%${values.userName}%`,
													},
												},
												{
													ssCardId: {
														iLike: `%${values.userName}%`,
													},
												},
											],
										},
									}),
							},
						],
						filterMeta: {
							...(values.userName && {
								websearchQuery: { searchVectorUser: values.userName },
							}),
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const { data: users }: { data: IUser[] | any; isLoading: boolean } = useQuery(
			['users', DebounsedSearch, currentPage, perPage],
			() =>
				UserService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
							limit: perPage,
							page: DebounsedSearch?.userName?.length ? 1 : currentPage,
							order: { createdAt: 'DESC' },
						},

						includeMeta: [
							{
								association: 'roles',
								required: true,

								where: {
									role: ['mainDoctor', 'doctor'],
								},
							},
							{
								...(values.userName &&
									/\d/.test(values?.userName) &&
									!isValid && {
										association: 'passport',
										required: true,
										where: {
											or: [
												{
													passportNumber: {
														iLike: `%${values.userName}%`,
													},
												},
												{
													ssCardId: {
														iLike: `%${values.userName}%`,
													},
												},
											],
										},
									}),
							},
						],
						filterMeta: {
							...(values.userName && {
								websearchQuery: { searchVectorUser: values.userName },
							}),
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		return (
			<>
				{!isUserDoctor && (
					<Dropdown isOpen={popperIsOpenUser} setIsOpen={setPopperIsOpenUser}>
						<DropdownToggle>
							<Button
								style={{
									boxSizing: 'content-box',
									border:
										values.users?.length > 0
											? '2px solid #1266f1'
											: `2px solid ${darkModeStatus ? '#212529' : '#f8f9fa'}`,
								}}
								color={themeStatus}
								className='text-nowrap me-2'>
								Врачу
							</Button>
						</DropdownToggle>
						<DropdownMenu
							style={{
								maxHeight: 600,
								width: '400px',
								overflow: 'scroll',
							}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									flexWrap: 'wrap',
									// overFlow: 'auto',
								}}>
								<FormGroup
									style={{ margin: '15px 0 15px 0' }}
									id='surname'
									label='Введите ФИО врача'
									className='col-10'>
									<Input
										type={'search'}
										name='userName'
										onChange={(e: any) => handleCheckboxChange(e)}
										value={values.userName}
									/>
									<div className='mb-1 col-12'>
										{users?.data?.length > 0 &&
											usersCount?.data?.filter(
												(i: any) => values?.users?.includes(i.uuid),
											)?.length > 0 &&
											usersCount.data
												.filter((i: any) => values.users.includes(i.uuid))
												.map((i: any) => (
													<>
														<div
															className='d-flex p-1 col-12'
															key={i.uuid}>
															<div className='col-10'>
																<p className='mb-1'>
																	{i?.surname} {i?.name}{' '}
																	{i?.middleName}
																</p>
															</div>
														</div>
														<hr
															className={`hr-divder-costum text-${canvasColor} bg-${canvasColor}`}
														/>
													</>
												))}
									</div>
								</FormGroup>
								{users?.data.length > 0 ? (
									users?.data.map((i: IUser, index: number) => (
										<div
											className='form-check col-10'
											key={`${index}-${i.uudi}`}>
											<input
												className='form-check-input'
												onChange={(e: any) => handleCheckboxChange(e)}
												type='checkbox'
												value={i.uuid}
												checked={values.users.includes(i.uuid)}
												name='users'
												id={`flexCheckDefault-${index}`}
											/>
											<label
												color={themeStatus}
												className='form-check-label'
												htmlFor={`flexCheckDefault-${index}`}>
												<Avatar
													src={
														i?.image
															? `https://backend.amracode.am${i?.image}`
															: UserImage3
													}
													srcSet={
														i?.image
															? `https://backend.amracode.am${i?.image}`
															: UserImage3
													}
													size={2.25 * dynamicFont}
													style={{ margin: '0 10px 0 0' }}
												/>
												{i?.surname} {i?.name} {i?.middleName}
											</label>
										</div>
									))
								) : (
									<h4>Врачей не найдено</h4>
								)}
							</div>
							<DropdownItem>
								<PaginationButtons
									data={users || []}
									label='customers'
									small
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									filterPagination
									perPage={perPage}
									setPerPage={setPerPage}
									totalPage={Math.ceil(usersCount?.data?.length / perPage) || 1}
								/>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				)}
			</>
		);
	},
);
export default UserFilterComponent;
