import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import PaginationButtons from '../../../components/PaginationButtons';
import TableComponent from '../../components/tables/TableComponent';
import { getFontSizeFromLocalStorage } from '../../../utils/functions';
import { TColor } from '@textnpayme/type/color-type';
import ServicesFilterComponent from './components/UI/FilterComponent/ServicesFilterComponent';
import ResetComponent from './components/UI/ResetComponent/ResetComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import SubHeaderRightComponent from './SubHeaderComponent/SubHeaderRightComponent';

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	services: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
	sort: any;
	setSort: any;
}

const ServicesTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		services,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
		sort,
		setSort,
	}) => {
		const [searchIconClicked, setSearchIconClicked] = useState(false);
		const isLargeDesktop = useMediaQuery({ query: '(min-width: 1620px)' });
		const isSmallDesktop = useMediaQuery({ query: '(max-width: 1300px)' });
		const isTablet = useMediaQuery({ query: '(max-width: 1250px)' });
		const [canvasColor, setCanvasColor] = useState<TColor>('light');

		const getRootFontSize = () =>
			parseFloat(getComputedStyle(document.documentElement).fontSize);
		const [dynamicFont, setDynamicFont] = useState(getRootFontSize());

		const updateFontSize = () => {
			const fontSizeValue = getFontSizeFromLocalStorage();
			setDynamicFont(fontSizeValue);
		};

		useEffect(() => {
			updateFontSize();
			const handleFontSizeChange = () => {
				updateFontSize();
			};
			window.addEventListener('fontSizeChange', handleFontSizeChange);

			return () => {
				window.removeEventListener('fontSizeChange', handleFontSizeChange);
			};
		}, []);

		const tableHeaders = [
			{ header: '№', key: 'index' },
			{ header: 'ID', key: 'serviceId' },
			{ header: 'Название', key: 'title_all' },
			{ header: 'Код', key: 'code' },
			{ header: 'Специализация', key: 'category' },
			{ header: 'Тип лечения', key: 'treatment_type' },
			{ header: 'Стоимость', key: 'price' },
		];

		const reset = useCallback(() => {
			setValues({
				specialization: [],
				serviceName: [],
				serviceId: undefined,
				title: undefined,
				order: { createdAt: 'DESC' },
			});
			setFilter({
				clinic: '',
				treatment_type: '',
				category: '',
				subCategory: '',
				code: '',
			});
			setSort(false);
		}, []);

		return (
			<>
				<Card stretch={isFluid}>
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						{/* <SubHeaderRightComponent /> turn back, when api is ready */}
					</div>
					<div className='table-row-header'>
						<div className='d-flex flex-row align-items-center w-full grow'>
							<ServicesFilterComponent
								filter={filter}
								setFilter={setFilter}
								reset={reset}
							/>
						</div>
						<div>
							<ResetComponent reset={reset} />
						</div>
					</div>
					<CardBody className='table-responsive' isScrollable={isFluid}>
						<TableComponent
							data={services}
							headers={tableHeaders}
							visitDropDownProps={undefined}
						/>
					</CardBody>
					{services?.meta?.count > perPage && (
						<PaginationButtons
							data={services || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(services?.meta?.count / perPage) || 1}
						/>
					)}
				</Card>
			</>
		);
	},
);
ServicesTable.defaultProps = {
	isFluid: false,
};

export default ServicesTable;
