import { authHost } from '../axios';
import { IPatient, IPatientCreate, IPatientUpdate } from '../interface/patient.interface';

export class PatientService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IPatient[] | null> {
		const { data } = await authHost.get(`patient?${query}`);
		return data;
	}

	static async search(query: any): Promise<IPatient[] | null> {
		const { data } = await authHost.get(`patient/search?${query}`);
		return data;
	}

	static async findOne(uuid: string, query?: any) {
		const { data } = await authHost.get(`patient/${uuid}?${query}`);
		return data;
	}

	static async findPayments(uuid: string, query?: any) {
		const { data } = await authHost.get(`estimate/getPatientFinance/${uuid}`);
		return data;
	}

	static async create(data: IPatientCreate) {
		const patient = await authHost.post('patient', data);
		return patient;
	}

	static async addFile(patientUuid: string, data: IPatientCreate) {
		const patient = await authHost.post(`patient/addFile/${patientUuid}`, data);
		return patient;
	}

	static async deleteFile(fileUuid: string, data: IPatientCreate) {
		const patient = await authHost.post(`patient/deleteFile/${fileUuid}`, data);
		return patient;
	}

	static async update(uuid: string, data: IPatientUpdate) {
		const patient = await authHost.patch(`patient/${uuid}`, data);
		return patient;
	}
}
