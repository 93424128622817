import React, { FC } from 'react';
import Button from '../../../../../../components/bootstrap/Button';
import Icon from '../../../../../../components/icon/Icon';
import Input from '../../../../../../components/bootstrap/forms/Input';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../../../components/bootstrap/Dropdown';
import { SubHeaderLeft } from '../../../../../../layout/SubHeader/SubHeader';
import classNames from 'classnames';
import useDarkMode from '../../../../../../hooks/useDarkMode';

interface IHeaderProps {
	search: any;
	setSearch(...args: unknown[]): unknown;
	sort: any;
	setSort(...args: unknown[]): unknown;
}

const Search: FC<IHeaderProps> = ({ search, setSearch, sort, setSort }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<SubHeaderLeft>
			<label className='border-0 bg-transparent cursor-pointer me-0' htmlFor='searchInput'>
				<Icon icon='Search' size='2x' color='primary' />
			</label>
			<Input
				id='searchInput'
				type='search'
				className='border-0 shadow-none bg-transparent'
				placeholder='Поиск пациента по Имени Фамилии Отчеству или по коду'
				onChange={(e: any) => setSearch(e.target.value)}
				value={search}
			/>
			<Dropdown className='fw-bold'>
				<DropdownToggle hasIcon={false}>
					<Button
						isOutline={!darkModeStatus}
						color='dark'
						isLight={darkModeStatus}
						aria-label='More actions'
						className={classNames(
							'text-nowrap',

							{
								'border-light': !darkModeStatus,
							},
							'fw-normal',
							'fs-6',
						)}>
						Сортировать по:
					</Button>
				</DropdownToggle>
				<DropdownMenu className='fw-bold'>
					<DropdownItem>
						<Button
							color={sort === 'Все' ? 'primary' : 'light'}
							isLight={sort === 'Все'}
							onClick={() => setSort('Все')}>
							Все
						</Button>
					</DropdownItem>
					<DropdownItem>
						<Button
							color={sort === 'Неактивный' ? 'primary' : 'light'}
							isLight={sort === 'Неактивный'}
							onClick={() => setSort('Неактивный')}>
							Неактивный
						</Button>
					</DropdownItem>
					<DropdownItem>
						<Button
							color={sort === 'Активный' ? 'primary' : 'light'}
							isLight={sort === 'Активный'}
							onClick={() => setSort('Активный')}>
							Активный
						</Button>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</SubHeaderLeft>
	);
};

export default Search;
