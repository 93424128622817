import Tooltips from '../../../../components/bootstrap/Tooltips';
import React from 'react';

const Name: React.FC<{ user: any; themeStatus: string; fs?: string }> = ({
	user,
	fs = '5',
	themeStatus,
}) => {
	return (
		<p
			style={{ maxWidth: 'fit-content' }}
			className={`d-flex flex-column text-nowrap align-items-start fs-${fs} ps-0 fw-bold mb-0 text-${
				themeStatus === 'light' ? 'dark' : 'light'
			}`}>
			{user?.name?.length + user?.middleName?.length + user?.surname?.length + 2 > 25 ? (
				<Tooltips title={`${user.surname} ${user.name} ${user.middleName}`}>
					<span
						className={`fs-${fs} 'text-truncate`}
						style={{ textAlign: 'left', maxWidth: 'fit-content' }}>
						{`${user.surname} ${user.name} ${user.middleName}`.slice(0, 22) + '...'}
					</span>
				</Tooltips>
			) : (
				<span className={`fs-${fs}`} style={{ textAlign: 'left', maxWidth: 'fit-content' }}>
					{`${user.surname} ${user.name} ${user.middleName}`}
				</span>
			)}

			<span className='small text-muted'>{user.phone}</span>
		</p>
	);
};
export default Name;
