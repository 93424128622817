import axios, { AxiosError } from 'axios';

const host = axios.create({
	baseURL: 'https://backend.amracode.am/api/v1/',
});

const authHost = axios.create({
	baseURL: 'https://backend.amracode.am/api/v1/',
});

type ErrorParam = AxiosError<{
	message: string;
	statusCode: number;
}>;

const authInterceptor = async (config: any) => {
	config.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`;
	return config;
};

const onResponseFail = (error: { status: any; response: { status: any }; message: string }) => {
	const status = error.status || error.response.status;
	if (status === 401 || (status === 400 && error.message === 'jwt expired')) {
		localStorage.removeItem('access_token');
	}
	return Promise.reject(error);
};

const handleTokenExpiration = () => {
	localStorage.removeItem('access_token');
	window.location.href = '/login';
};

authHost.interceptors.request.use(authInterceptor, onResponseFail);

const tokenExpiredMessages = ['jwt expired', 'jwt malformed'];
const tokenInvalidMessages = ['invalid token', 'invalid signature', 'try_login_again_to_verify'];

export const onProtectedApiRejected = async (error: ErrorParam) => {
	if (error.response?.data.statusCode !== 400) return Promise.reject(error);

	const message = error.response?.data.message ?? '';

	if (tokenExpiredMessages.includes(message)) {
		try {
			const res = await host.post<{
				access_token: string;
				refresh_token: string;
			}>(
				`auth/refresh`,
				{
					refreshToken: localStorage.getItem('refresh_token'),
					clinicUuid: localStorage.getItem('clinicUuid'),
				},
				{
					headers: {
						authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
					},
				},
			);
			localStorage.setItem('access_token', res.data.access_token);
			localStorage.setItem('refresh_token', res.data.refresh_token);
		} catch (e) {
			localStorage.removeItem('access_token');
			if (window.location.pathname !== '/login') {
				window.location.href = '/login';
			}
		}

		return Promise.reject(error);
	}

	if (tokenInvalidMessages.includes(message) && window.location.pathname !== '/login') {
		window.location.href = '/login';
	}

	return Promise.reject(error);
};

authHost.interceptors.response.use(undefined, onProtectedApiRejected);

// authHost.interceptors.response.use(
// 	(response: any) => {
// 		return response;
// 	},
// 	(error: any) => {
// 		if (
// 			error.response &&
// 			error.response.data &&
// 			error.response.data.message &&
// 			error.response.data.statusCode === 400
// 		) {
// 			const errorMessage = error.response.data.message;
// 			if (errorMessage.includes('jwt expired')) {
// 				handleTokenExpiration();
// 			}
// 		}
// 		return Promise.reject(error);
// 	},
// );

export { host, authHost, handleTokenExpiration };
