import React, { SVGProps } from 'react';

const SvgTheethChooseService = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path opacity="0.3" d="M3.33331 14.655H14.6666V6.15495H3.33331V14.655ZM6.16665 9.69662H8.29165V7.57162H9.70831V9.69662H11.8333V11.1133H9.70831V13.2383H8.29165V11.1133H6.16665V9.69662Z" fill="#0D6EFD"/>
			<path d="M14.6666 4.73816H11.8332V3.3215C11.8332 2.54233 11.1957 1.90483 10.4166 1.90483H7.58323C6.80406 1.90483 6.16656 2.54233 6.16656 3.3215V4.73816H3.33323C2.55406 4.73816 1.91656 5.37566 1.91656 6.15483V14.6548C1.91656 15.434 2.55406 16.0715 3.33323 16.0715H14.6666C15.4457 16.0715 16.0832 15.434 16.0832 14.6548V6.15483C16.0832 5.37566 15.4457 4.73816 14.6666 4.73816ZM7.58323 3.3215H10.4166V4.73816H7.58323V3.3215ZM14.6666 14.6548H3.33323V6.15483H14.6666V14.6548Z" fill="#0D6EFD"/>
			<path d="M8.29152 13.2378H9.70807V11.113H11.8329V9.69644H9.70807V7.57161H8.29152V9.69644H6.16669V11.113H8.29152V13.2378Z" fill="#0D6EFD"/>
		</svg>
	);
};

export default SvgTheethChooseService;
