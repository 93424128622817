import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import UserImage3 from '../../../../assets/img/wanna/wanna3.png';
import Avatar from '../../../../components/Avatar';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PassportService } from '../../../../custom/api/service/passport.service';
import * as _ from 'lodash';
import { IPassport } from '@textnpayme/custom/api/interface/passport.interface';
import InputMask from 'react-input-mask';
import { UserService } from '../../../../custom/api/service/user.service';
import * as qs from 'qs';
import { ClinicService } from '../../../../custom/api/service/clinic.service';
import { CategoryService } from '../../../../custom/api/service/category.service';
import { message, notification } from 'antd';
import type { NotificationArgsProps } from 'antd';

type NotificationPlacement = NotificationArgsProps['placement'];

interface IEditProfileProps {
	id: string;
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	patient: any;
}

interface UpdatePatientParams {
	uuid: string;
	inputDto: any;
}
interface UpdatePassportParams {
	uuid: string;
	inputDto: IPassport;
}
interface CreatePassportParams {
	inputDto: IPassport;
}

const EditProfile: FC<IEditProfileProps> = ({ id, isOpen, setIsOpen, patient }) => {
	const [values, setValues] = useState<any>({});
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [canChangeRole, setCanChangeRole] = useState(true);

	const [api, contextHolder] = notification.useNotification();

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
		});
	};

	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(
			qs.stringify({
				includeMeta: [
					{
						association: 'roles',
					},
				],
			}),
		),
	);

	const { data: clinics } = useQuery<any>(['clinics'], () =>
		ClinicService.findAll(qs.stringify({})),
	);

	useEffect(() => {
		if (user_role?.roles?.find((i: any) => i.role === 'superAdmin')) {
			setIsSuperAdmin(true);
		}
	}, [user_role]);

	useEffect(() => {
		if (patient?.roles?.find((i: any) => i.role === 'superAdmin' || i.role === 'admin')) {
			setCanChangeRole(false);
		}
	}, [patient]);

	const changeValues = (key: string, value: string | number) => {
		setValues((prev: any) => ({ ...prev, [key]: value }));
	};

	const getRole = (data: any, categories: any) => {
		const clinics = data?.map((item: any) => {
			// Find all matching categories for the clinicUuid
			const matchedCategories = categories
				?.filter((cat: any) => cat.clinicUuid === item.clinicUuid)
				?.map((cat: any) => cat);
			const category_data = {
				subCategoryUuid:
					matchedCategories?.find(
						(subCategory: any) =>
							subCategory.clinicUuid === item.clinicUuid &&
							subCategory.catgeories.categoryUuid,
					)?.categoryUuid ?? '',
				categoryUuid:
					matchedCategories?.find(
						(catgeory: any) =>
							catgeory.clinicUuid === item.clinicUuid &&
							!catgeory.catgeories.categoryUuid,
					)?.categoryUuid ?? '',
			};
			return {
				uuid: item.clinicUuid,
				role: item.role,
				...category_data,
			};
		});
		return clinics;
	};

	const handleCheckboxChange = (e: any, clinicUuid: string) => {
		const { checked } = e.target;
		setValues((prev: any) => {
			const clinics = [...prev.clinics];

			if (checked) {
				if (!clinics?.some((clinic) => clinic.uuid === clinicUuid)) {
					clinics?.push({ uuid: clinicUuid, role: 'admin' });
				}
			} else {
				const index = clinics?.findIndex((clinic) => clinic.uuid === clinicUuid);
				if (index > -1) clinics?.splice(index, 1);
			}

			return { ...prev, clinics };
		});
	};

	const handleRoleChange = (e: any, clinicUuid: string) => {
		const { value, name } = e.target;

		setValues((prev: any) => {
			// Create a shallow copy of the clinics array
			const clinics = [...prev.clinics];

			// Find the index of the clinic with the given uuid
			const clinicIndex = clinics.findIndex((clinic) => clinic.uuid === clinicUuid);

			if (clinicIndex > -1) {
				// If found, update the appropriate field
				clinics[clinicIndex][name] = value;
			} else {
				// If not found, add a new clinic
				clinics.push({
					[name]: value, // Dynamically set the property based on name
					uuid: clinicUuid,
				});
			}

			// Return the new state with the updated clinics array
			return {
				...prev,
				clinics,
			};
		});
	};

	useEffect(() => {
		const baseValues = {
			middleName: patient?.middleName || '',
			email: patient?.email || '',
			name: patient?.name || '',
			surname: patient?.surname || '',
			age: patient?.age || '',
			gender: patient?.gender || '',
			patientId: patient?.patientId || '',
			userUuid: patient?.uuid || '',
			phone: patient?.phone || '',
			imagePath: patient?.image ? `https://backend.amracode.am${patient?.image}` : '',
			image: patient?.image || '',
			country: patient?.passport?.country || '',
			dateOfIssue: patient?.passport?.dateOfIssue || '',
			dateOfExpiry: patient?.passport?.dateOfExpiry || '',
			countryCode: patient?.passport?.countryCode || '',
			address: patient?.address || '',
			passportNumber: patient?.passport?.passportNumber || '',
			ssCardId: patient?.passport?.ssCardId || '',
			clinics: getRole(patient?.roles, patient?.categories),
		};
		if (patient?.passport) {
			setValues({
				...baseValues,
				passportUuid: patient.passport.uuid || null,
			});
		}
		setValues(baseValues);
		if (patient?.dateOfBirth) {
			changeValues('dateOfBirth', patient.dateOfBirth);
		}
	}, [patient]);

	const handleImageChange = (e: any) => {
		const file = e.target.files[0];
		e.target.value = '';
		if (file) {
			const imageUrl = URL.createObjectURL(file);
			changeValues('image', file);
			changeValues('imageBlob', imageUrl);
		}
	};

	const handleImageDelete = () => {
		changeValues('imagePath', '');
		changeValues('image', '');
		changeValues('imageBlob', '');
	};

	const queryClient = useQueryClient();

	const { mutate: patientUpdate } = useMutation(
		({ uuid, inputDto }: UpdatePatientParams) => UserService.update(uuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('users');
				queryClient.invalidateQueries('user');
				setIsOpen(false);

				setValues({
					middleName: patient?.middleName || '',
					email: patient?.email || '',
					name: patient?.name || '',
					surname: patient?.surname || '',
					patientId: patient?.patientId || '',
					gender: patient?.gender || '',
					phone: patient?.phone || '',
					userUuid: patient?.uuid || '',
					imagePath: patient?.image ? `https://backend.amracode.am${patient?.image}` : '',
					image: patient?.image || '',
					country: patient?.passport?.country || '',
					dateOfIssue: patient?.passport?.dateOfIssue || '',
					dateOfExpiry: patient?.passport?.dateOfExpiry || '',
					countryCode: patient?.passport?.countryCode || '',
					dateOfBirth: patient?.dateOfBirth || '',
					address: patient?.address || '',
					passportNumber: patient?.passport?.passportNumber || '',
					ssCardId: patient?.passport?.ssCardId || '',
					clinics: getRole(patient?.roles, patient?.categories),
				});
			},
			onError: (error: any) => {
				if (error.response.data.message === 'permission_denied')
					openNotification('bottomRight', 'Важно !', 'Нет доступа');

				if (error.response.data.message === 'must_have_clinic_role')
					openNotification('bottomRight', 'Важно !', 'Выберите клинику и роль');

				if (error.response.data.message === 'must_choose_clinic')
					openNotification('bottomRight', 'Важно !', 'Выберите клинику');

				if (error.response.data.message === 'must_choose_role')
					openNotification('bottomRight', 'Важно !', 'Выберите роль');

				if (error.response.data.message === 'admin_can_change_only_own_clinic')
					openNotification(
						'bottomRight',
						'Важно !',
						'Админ может поменять роль только в своем клинике ',
					);

				if (error.response.data.message === 'cannot_remove_all_roles')
					openNotification(
						'bottomRight',
						'Важно !',
						'Невозможно удалить всё роли пользователя',
					);
			},
		},
	);

	const { mutate: passportUpdate } = useMutation(
		({ uuid, inputDto }: UpdatePassportParams) => PassportService.update(uuid, inputDto),
		{
			onSuccess: (response) => {
				// setIsOpen(false);
				queryClient.invalidateQueries('users');
				queryClient.invalidateQueries('user');
				const formData = new FormData();

				// Append fields
				Object.entries({ ...values, passportUuid: response?.data?.uuid }).forEach(
					([key, value]) => {
						if (key === 'clinics' && Array.isArray(value)) {
							// Append clinics as a JSON string
							formData.append(key, JSON.stringify(value));
						} else if (key === 'image' && value instanceof File) {
							// Append image file separately
							formData.append(key, value);
						} else {
							formData.append(key, value as string);
						}
					},
				);

				const uuid = patient.uuid;
				patientUpdate({ uuid, inputDto: formData });
			},
			onError: (error: any) => {
				if (error.response.data.message === 'passport_number_unique')
					openNotification(
						'bottomRight',
						'Важно !',
						'Пациент с таким номером паспорта уже существует',
					);

				if (error.response.data.message === 'ss_card_unique')
					openNotification(
						'bottomRight',
						'Важно !',
						'Пациент с таким номером социальной карты уже существует',
					);
			},
		},
	);

	const { mutate: passportCreate } = useMutation(
		({ inputDto }: CreatePassportParams) => PassportService.create(inputDto),
		{
			onSuccess: (response) => {
				setIsOpen(false);
				const formData = new FormData();

				Object.entries({ ...values, passportUuid: response?.data?.uuid }).forEach(
					([key, value]) => {
						if (key === 'clinics' && Array.isArray(value)) {
							// Append clinics as a JSON string
							formData.append(key, JSON.stringify(value));
						} else if (key === 'image' && value instanceof File) {
							// Append image file separately
							formData.append(key, value);
						} else {
							formData.append(key, value as string);
						}
					},
				);

				const uuid = patient.uuid;
				patientUpdate({ uuid, inputDto: formData });
			},
			onError: (error: any) => {
				if (error.response.data.message === 'passport_number_unique')
					openNotification(
						'bottomRight',
						'Важно !',
						'Пациент с таким номером паспорта уже существует',
					);

				if (error.response.data.message === 'ss_card_unique')
					openNotification(
						'bottomRight',
						'Важно !',
						'Пациент с таким номером социальной карты уже существует',
					);
			},
		},
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!!patient?.passport) {
			const passportUuid = patient?.passport?.uuid; // Assuming the patient's UUID is available
			passportUpdate({
				uuid: passportUuid,
				inputDto: _.pick(values, [
					'passportNumber',
					'ssCardId',
					'dateOfIssue',
					'dateOfExpiry',
					'country',
					'countryCode',
					'dateOfBirth',
					'name',
					'surname',
					'middleName',
					'userUuid',
				]),
			});
		} else {
			passportCreate({
				inputDto: _.pick(values, [
					'passportNumber',
					'ssCardId',
					'dateOfIssue',
					'dateOfExpiry',
					'country',
					'countryCode',
					'dateOfBirth',
					'name',
					'surname',
					'middleName',
					'userUuid',
				]),
			});
		}

		// patientUpdate({ uuid, inputDto: formData });
	};

	const sanitizeInput = (input: string): string => {
		return input.replace(/_/g, '');
	};

	const validateFullDateOfBirth = (dateString: string): boolean => {
		const sanitizedDate = sanitizeInput(dateString);
		const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

		if (!dateRegex.test(sanitizedDate)) return false;

		const [year, month, day] = sanitizedDate.split('-')?.map(Number);

		const isValidDate = (y: number, m: number, d: number) => {
			const date = new Date(y, m - 1, d);
			return date.getFullYear() === y && date.getMonth() === m - 1 && date.getDate() === d;
		};

		if (!isValidDate(year, month, day)) {
			return false;
		}

		const currentDate = new Date();
		const minDate = new Date();
		minDate.setFullYear(currentDate.getFullYear() - 150);

		const inputDate = new Date(year, month - 1, day);

		if (inputDate > currentDate || inputDate < minDate) {
			return false;
		}
		return true;
	};

	const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
		['categories'],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'subCategory',
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const categories = categoriesWithSubCategories?.data
		.filter((item: any) => !item.categoryUuid)
		?.map((category: any) => ({
			...category,
			categoriesUuid: category.uuid,
		}));

	const subCategories = categoriesWithSubCategories?.data
		?.filter((item: any) => item.categoryUuid)
		?.map((category: any) => ({
			...category,
			categoriesUuid: category.uuid,
		}));

	return (
		<Modal
			isStaticBackdrop
			isCentered
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			size='lg'
			titleId={id.toString()}>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<span className='fs-3 fw-bold'>
					<Icon icon='Person' className='me-2 fs-2' color='primary' />
					Редактировать профиль
				</span>
			</ModalHeader>
			<form
				onSubmit={handleSubmit}
				className='d-flex flex-column gap-4'
				style={{ padding: '1.5rem 2.25rem' }}>
				<ModalBody className='p-0'>
					<div className='d-flex flex-row justify-content-between align-items-start'>
						{contextHolder}
						<div className='d-flex flex-column justify-content-between gap-4'>
							<Avatar
								src={values.imageBlob || values?.imagePath || UserImage3} // Show preview or default image
								size={200}
								color='primary'
							/>
							<input
								type='file'
								accept='image/*'
								onChange={(e: any) => handleImageChange(e)}
								style={{ display: 'none' }}
								id='upload-input'
								disabled={values.image || values.imageBlob}
							/>
							<label
								htmlFor='upload-input'
								style={{ cursor: 'pointer', position: 'relative', zIndex: '1' }}
								className='col-12'>
								<Button
									color='primary'
									isDisable={values.image || values.imageBlob}
									style={{
										display: (values.image || values.imageBlob) && 'none',
										width: '100%',
										position: 'relative',
										zIndex: '-1',
									}}>
									Загрузить
								</Button>
							</label>
							{(values.image || values.imageBlob) && (
								<Button color='primary' onClick={handleImageDelete}>
									удалить
								</Button>
							)}
						</div>
						<div className='d-flex flex-column gap-4'>
							<FormGroup className='col-12' id='surname' label='Фамилия'>
								<Input
									type='text'
									onChange={(e: any) => changeValues('surname', e.target.value)}
									value={values.surname}
								/>
							</FormGroup>
							<FormGroup className='col-12' id='name' label='Имя'>
								<Input
									type='text'
									onChange={(e: any) => changeValues('name', e.target.value)}
									value={values.name}
								/>
							</FormGroup>
							<FormGroup className='col-12' id='middleName' label='Отчество'>
								<Input
									type='text'
									onChange={(e: any) =>
										changeValues('middleName', e.target.value)
									}
									value={values.middleName}
								/>
							</FormGroup>
							<FormGroup
								aria-required
								className='col-12'
								id='dateOfBirth'
								label='Дата рождения *'>
								<InputMask
									required
									mask='9999-99-99'
									className='input-date form-control'
									placeholder='гггг-мм-дд'
									onChange={(e: any) => {
										const inputValue = e.target.value;
										changeValues('dateOfBirth', inputValue);

										const sanitizedDate = sanitizeInput(inputValue);
										if (sanitizedDate.length === 10) {
											const isValid = validateFullDateOfBirth(inputValue);
											if (!isValid) {
												message.error(
													'Неверный формат даты или дата вне допустимого диапазона',
												);
											}
										}
									}}
									value={values?.dateOfBirth ? `${values.dateOfBirth}` : ''}
								/>
							</FormGroup>
							<FormGroup id='gender' label='Пол'>
								<div style={{ position: 'relative' }}>
									<select
										onChange={(e) => changeValues('gender', e.target.value)}
										value={values?.gender ?? ''}
										className='form-select'
										aria-label='Выберите пол'>
										<option value='' disabled>
											Выберите пол
										</option>
										<option value='Мужской'>Мужской</option>
										<option value='Женский'>Женский</option>
									</select>
								</div>
							</FormGroup>
							<FormGroup className='col-12' id='phone' label='Номер телефона'>
								<InputMask
									mask='+374 (99) 99-99-99'
									className='form-control'
									placeholder='+374 (__) __-__-__'
									onChange={(e: any) => changeValues('phone', e.target.value)}
									value={values.phone}
								/>
							</FormGroup>
							<FormGroup className='col-12' id='address' label='Адрес'>
								<Input
									type='text'
									onChange={(e: any) => changeValues('address', e.target.value)}
									value={values.address}
								/>
							</FormGroup>
						</div>

						<div className='d-flex flex-column gap-4'>
							<FormGroup className='col-12' id='email' label='Эл. почта'>
								<Input
									type='email'
									placeholder='example@mail.com'
									onChange={(e: any) => changeValues('email', e.target.value)}
									value={values.email}
								/>
							</FormGroup>
							<FormGroup className='col-12' id='socialCard' label='Дата выдачи *'>
								<InputMask
									required
									mask='9999-99-99'
									className='input-date form-control'
									placeholder='гггг-мм-дд'
									onChange={(e: any) => {
										const inputValue = e.target.value;
										changeValues('dateOfExpiry', inputValue);

										const sanitizedDate = sanitizeInput(inputValue);
										if (sanitizedDate.length === 10) {
											const isValid = validateFullDateOfBirth(inputValue);
											if (!isValid) {
												message.error(
													'Неверный формат даты или дата вне допустимого диапазона',
												);
											}
										}
									}}
									value={values?.dateOfExpiry ? `${values.dateOfExpiry}` : ''}
								/>
							</FormGroup>
							<FormGroup className='col-12' id='socialCard' label='Дата оканчания *'>
								<InputMask
									required
									mask='9999-99-99'
									className='input-date form-control'
									placeholder='гггг-мм-дд'
									onChange={(e: any) => {
										const inputValue = e.target.value;
										changeValues('dateOfIssue', inputValue);

										const sanitizedDate = sanitizeInput(inputValue);
										if (sanitizedDate.length === 10) {
											const isValid = validateFullDateOfBirth(inputValue);
											if (!isValid) {
												message.error(
													'Неверный формат даты или дата вне допустимого диапазона',
												);
											}
										}
									}}
									value={values?.dateOfIssue ? `${values.dateOfIssue}` : ''}
								/>
							</FormGroup>

							<FormGroup className='col-12' id='email' label='Страна *'>
								<Input
									required
									onChange={(e: any) => changeValues('country', e.target.value)}
									value={values.country}
								/>
							</FormGroup>

							<FormGroup className='col-12' id='disease' label='Орган выдавший *'>
								<InputMask
									className='form-control'
									mask='9999'
									required
									type='text'
									onChange={(e: any) =>
										changeValues('countryCode', e.target.value)
									}
									value={values.countryCode}
								/>
							</FormGroup>

							<FormGroup
								className='col-12'
								id='socialCard'
								label='Номер соц. карты  *'>
								<InputMask
									mask='9999999999'
									className='form-control'
									placeholder='0123456789'
									onChange={(e: any) => changeValues('ssCardId', e.target.value)}
									value={values.ssCardId}
								/>
							</FormGroup>

							<FormGroup
								className='col-12'
								id='passportNumber'
								label='Номер паспорта  *'>
								<InputMask
									mask='aa999999'
									className='form-control'
									placeholder='AM123456'
									onChange={(e: any) =>
										changeValues('passportNumber', e.target.value)
									}
									value={values.passportNumber}
								/>
							</FormGroup>
						</div>
					</div>
					{/* {canChangeRole && (
						<div className='col-12'>
							{isSuperAdmin && clinics?.data?.length > 0 ? (
								<FormGroup label={'Роль'} className='col-12 fs-6'>
									<div style={{ position: 'relative' }}>
										{clinics?.data?.map((item: any, index: number) => (
											<div key={index}>
												<input
													type='checkbox'
													id={item.name}
													defaultValue={item.uuid}
													name='clinicUuid'
													checked={values.clinics?.some(
														(clinic: any) => clinic.uuid === item.uuid,
													)}
													onChange={(e) =>
														handleCheckboxChange(e, item.uuid)
													}
												/>
												<label
													style={{ margin: '5px 0' }}
													htmlFor={item.name}
													className='checkBoxContainer'>
													<span
														style={{ marginRight: 10 }}
														className='custom-checkbox'>
														<span></span>
													</span>
													{item.name}
												</label>
												<select
													disabled={
														!values.clinics?.some(
															(clinic: any) =>
																clinic.uuid === item.uuid,
														)
													}
													value={
														values?.clinics?.find(
															(clinic: any) =>
																clinic.uuid === item.uuid,
														)?.role || ''
													}
													className='form-select'
													onChange={(e: any) =>
														handleRoleChange(e, item.uuid)
													}
													name='role'>
													<option disabled value=''>
														выберите роль
													</option>
													<option value='mainDoctor'>Главный врач</option>
													<option value='doctor'>Доктор</option>
													<option value='nurse'>Медсестра</option>
													<option value='receptionist'>
														Регистратура
													</option>
													<option value='employee'>Работник</option>
													<option value='user'>Пользователь</option>
												</select>
												<div className='col-12 d-flex'>
													<div className='col-6 d-flex justify-content-start'>
														<FormGroup
															className='col-11'
															label='Специализация'>
															<select
																value={
																	values?.clinics?.find(
																		(clinic: any) =>
																			clinic.uuid ===
																			item.uuid,
																	)?.categoryUuid || ''
																}
																className='form-select'
																onChange={(e: any) =>
																	handleRoleChange(e, item.uuid)
																}
																name='categoryUuid'>
																{categories?.map((item: any) => (
																	<option value={item.uuid}>
																		{item.name.en}
																	</option>
																))}
																<option
																	value=''
																	hidden
																	selected></option>
															</select>
														</FormGroup>
													</div>
													<div className='col-6 d-flex justify-content-end'>
														<FormGroup
															className='col-11'
															id='surname'
															label='Подспециализация'>
															<select
																disabled={
																	!values?.clinics?.find(
																		(clinic: any) =>
																			clinic.uuid ===
																			item.uuid,
																	)?.categoryUuid
																}
																value={
																	values?.clinics?.find(
																		(clinic: any) =>
																			clinic.uuid ===
																			item.uuid,
																	)?.subCategoryUuid || ''
																}
																className='form-select'
																onChange={(e: any) =>
																	handleRoleChange(e, item.uuid)
																}
																name='subCategoryUuid'>
																{subCategories
																	?.filter(
																		(subCategory: any) =>
																			subCategory.categoryUuid ===
																			values?.clinics?.find(
																				(clinic: any) =>
																					clinic.uuid ===
																					item.uuid,
																			)?.categoryUuid,
																	)
																	?.map((item: any) => (
																		<option value={item.uuid}>
																			{item.name.en}
																		</option>
																	))}
																<option
																	value=''
																	hidden
																	selected></option>
															</select>
														</FormGroup>
													</div>
												</div>
											</div>
										))}
									</div>
								</FormGroup>
							) : (
								<FormGroup label={'Роль'} className='col-12 fs-6'>
									<div style={{ position: 'relative' }}>
										<div>
											<select
												value={
													values?.clinics?.find(
														(clinic: any) =>
															clinic.uuid === user_role?.clinicUuid,
													)?.role || ''
												}
												className='form-select'
												onChange={(e: any) => {
													handleRoleChange(e, user_role?.clinicUuid);
												}}
												name='role'>
												<option disabled value=''>
													выберите роль
												</option>
												<option value='mainDoctor'>Главный врач</option>
												<option value='doctor'>Доктор</option>
												<option value='nurse'>Медсестра</option>
												<option value='receptionist'>Регистратура</option>
												<option value='employee'>Работник</option>
												<option value='user'>Пользователь</option>
											</select>
											<div className='col-12 d-flex justify-content-end'>
												<div className='col-6 d-flex justify-content-start'>
													<FormGroup
														className='col-11'
														id='surname'
														label='Специализация'>
														<select
															value={
																values?.clinics?.find(
																	(clinic: any) =>
																		clinic.uuid ===
																		user_role?.clinicUuid,
																)?.categoryUuid || ''
															}
															className='form-select'
															onChange={(e: any) =>
																handleRoleChange(
																	e,
																	user_role?.clinicUuid,
																)
															}
															name='categoryUuid'>
															{categories?.map((item: any) => (
																<option value={item.uuid}>
																	{item.name.en}
																</option>
															))}
															<option
																value=''
																hidden
																selected></option>
														</select>
													</FormGroup>
												</div>
												<div className='col-6 d-flex justify-content-end'>
													<FormGroup
														className='col-11'
														id='surname'
														label='Подспециализация'>
														<select
															value={
																values?.clinics?.find(
																	(clinic: any) =>
																		clinic.uuid ===
																		user_role?.clinicUuid,
																)?.subCategoryUuid || ''
															}
															className='form-select'
															onChange={(e: any) =>
																handleRoleChange(
																	e,
																	user_role?.clinicUuid,
																)
															}
															name='subCategoryUuid'>
															{subCategories
																?.filter(
																	(subCategory: any) =>
																		subCategory.categoryUuid ===
																		values?.clinics?.find(
																			(clinic: any) =>
																				clinic.uuid ===
																				user_role?.clinicUuid,
																		)?.subCategoryUuid,
																)
																?.map((item: any) => (
																	<option value={item.uuid}>
																		{item.name.en}
																	</option>
																))}
															<option
																value=''
																hidden
																selected></option>
														</select>
													</FormGroup>
												</div>
											</div>
										</div>
									</div>
								</FormGroup>
							)}
						</div>
					)} */}
				</ModalBody>
				<ModalFooter className='p-0'>
					<div className='d-flex justify-content-between w-100'>
						<Button
							className='fw-bold fs-6'
							color='link'
							isLight
							style={{ color: '#0D6EFD' }}
							onClick={() => {
								setIsOpen(false);
							}}>
							Отменить
						</Button>

						<Button className='fw-bold fs-6' color='info' type={'submit'}>
							Сохранить
						</Button>
					</div>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default EditProfile;
