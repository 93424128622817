import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { CardFooter, CardFooterLeft, CardFooterRight } from './bootstrap/Card';
import Pagination, { PaginationItem } from './bootstrap/Pagination';
import Select from './bootstrap/forms/Select';
import Option from './bootstrap/Option';
import { deepCopyAll } from '../utils/functions';

export const PER_COUNT = {
	1: 1,
	3: 3,
	5: 5,
	10: 10,
	25: 25,
	50: 50,
};

export const PER_COUNT_FILTER = {
	5: 5,
	10: 10,
};
export const PER_COUNT_MEDICAL_CASE = {
	1: 1,
	2: 2,
	3: 3,
};

export const PER_COUNT_MEDICAL_CASE_TABLE = {
	6: 6,
	12: 12,
};

export const dataPagination = (data: any[], currentPage: number, perPage: number): any => {
	const startIndex = (currentPage - 1) * perPage;
	const endIndex = startIndex + perPage;

	// Filter the data array to get the correct portion for the current page
	const filtered_data = data?.filter((item, index) => index >= startIndex && index < endIndex);
	return filtered_data;
};

interface IPaginationButtonsProps {
	setCurrentPage: any;
	currentPage: number;
	perPage: number;
	small?: boolean;
	medicalCaseTable?: boolean;
	medicalCase?: boolean;
	setPerPage(...args: unknown[]): unknown;
	data: any;
	totalPage?: number;
	filterPagination?: boolean;
	label?: string;
	serviceModal?: boolean;
	className?: string;
}
const PaginationButtons: FC<IPaginationButtonsProps> = ({
	setCurrentPage,
	filterPagination,
	medicalCaseTable,
	currentPage,
	perPage,
	setPerPage,
	medicalCase,
	data,
	small,
	label,
	totalPage = 3,
	className = 'card-footer',
}) => {
	const pagination = () => {
		let items = [];

		let i = currentPage - 1;
		while (i >= currentPage - 1 && i > 0) {
			items.push(
				<PaginationItem key={i} onClick={() => setCurrentPage(currentPage - 1)}>
					{i}
				</PaginationItem>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<PaginationItem
				key={currentPage}
				isActive
				onClick={() => {
					setCurrentPage(currentPage);
				}}>
				{currentPage}
			</PaginationItem>,
		);

		i = currentPage + 1;
		while (i <= currentPage + 1 && i <= totalPage) {
			items.push(
				<PaginationItem key={i} onClick={() => setCurrentPage(currentPage + 1)}>
					{i}
				</PaginationItem>,
			);

			i += 1;
		}

		return items;
	};

	const getInfo = () => {
		const start = perPage * (currentPage - 1) + 1;

		const end = perPage * currentPage;

		return (
			<span className='pagination__desc'>
				{/* Showing {start} to {end > totalPage ? totalPage : end} of {totalPage} {label} */}
			</span>
		);
	};

	return (
		<div className={className} style={{ backgroundColor: 'inherit' }}>
			{!filterPagination ? (
				<>
					<CardFooterLeft>
						<span className='text-muted'>{getInfo()}</span>
					</CardFooterLeft>

					<CardFooterRight className='d-flex'>
						{totalPage > 1 && (
							// @ts-ignore
							<Pagination ariaLabel={label}>
								<PaginationItem
									isFirst
									isDisabled={!(currentPage - 1 > 0)}
									onClick={() => setCurrentPage(1)}
								/>
								<PaginationItem
									isPrev
									isDisabled={!(currentPage - 1 > 0)}
									onClick={() => setCurrentPage(currentPage - 1)}
								/>
								{currentPage - 1 > 1 && (
									<PaginationItem onClick={() => setCurrentPage(currentPage - 2)}>
										...
									</PaginationItem>
								)}
								{pagination()}
								{currentPage + 1 < totalPage && (
									<PaginationItem onClick={() => setCurrentPage(currentPage + 2)}>
										...
									</PaginationItem>
								)}
								<PaginationItem
									isNext
									isDisabled={!(currentPage + 1 <= totalPage)}
									onClick={() => setCurrentPage(currentPage + 1)}
								/>
								<PaginationItem
									isLast
									isDisabled={!(currentPage + 1 <= totalPage)}
									onClick={() => setCurrentPage(totalPage)}
								/>
							</Pagination>
						)}

						<Select
							size='sm'
							ariaLabel='Per'
							onChange={(e: { target: { value: string } }) => {
								setPerPage(parseInt(e.target.value, 10));
								setCurrentPage(1);
							}}
							value={perPage.toString()}>
							{Object.keys(
								small
									? PER_COUNT_FILTER
									: medicalCaseTable
									  ? PER_COUNT_MEDICAL_CASE_TABLE
									  : medicalCase
									    ? PER_COUNT_MEDICAL_CASE
									    : PER_COUNT,
							).map((i) => (
								<Option key={i} value={i}>
									{i}
								</Option>
							))}
						</Select>
					</CardFooterRight>
				</>
			) : (
				<>
					<div className='d-flex justify-content-between col-12'>
						<div className='col-10 d-flex justify-content-center'>
							{totalPage > 1 && (
								// @ts-ignore
								<Pagination ariaLabel={label}>
									<PaginationItem
										isFirst
										isDisabled={!(currentPage - 1 > 0)}
										onClick={() => setCurrentPage(1)}
									/>
									<PaginationItem
										isPrev
										isDisabled={!(currentPage - 1 > 0)}
										onClick={() => setCurrentPage(currentPage - 1)}
									/>

									{currentPage - 1 > 1 && (
										<PaginationItem
											onClick={() => setCurrentPage(currentPage - 2)}>
											...
										</PaginationItem>
									)}

									{pagination()}

									{currentPage + 1 < totalPage && (
										<PaginationItem
											onClick={() => setCurrentPage(currentPage + 2)}>
											...
										</PaginationItem>
									)}
									<PaginationItem
										isNext
										isDisabled={!(currentPage + 1 <= totalPage)}
										onClick={() => setCurrentPage(currentPage + 1)}
									/>

									<PaginationItem
										isLast
										isDisabled={!(currentPage + 1 <= totalPage)}
										onClick={() => setCurrentPage(totalPage)}
									/>
								</Pagination>
							)}
						</div>

						<div className='col-auto'>
							<Select
								size='sm'
								ariaLabel='Per'
								onChange={(e: { target: { value: string } }) => {
									setPerPage(parseInt(e.target.value, 10));
									setCurrentPage(1);
								}}
								value={perPage.toString()}>
								{Object.keys(
									small
										? PER_COUNT_FILTER
										: medicalCase
										  ? PER_COUNT_MEDICAL_CASE
										  : PER_COUNT,
								).map((i) => (
									<Option key={i} value={i}>
										{i}
									</Option>
								))}
							</Select>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
PaginationButtons.propTypes = {
	setCurrentPage: PropTypes.func.isRequired,
	currentPage: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	setPerPage: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.any.isRequired,
	label: PropTypes.string,
	totalPage: PropTypes.any,
};
PaginationButtons.defaultProps = {
	label: 'items',
	totalPage: 1,
};

export default PaginationButtons;
