import React from 'react';
import { Select } from 'antd';
import useDarkMode from '../../../hooks/useDarkMode';

interface SelectProps {
	changeValues: (key: string, value: string | number, index?: number, idx?: number) => void;
	changeSearchValue: (key: string, value: string | number) => void;
	options: any[] | undefined;
	value: string | undefined;
	name: string;
	placeholder: string;
	disabled?: any;
	index?: number;
	idx?: number;
}
export default function CustomSelect({
	changeValues,
	changeSearchValue,
	value,
	name,
	placeholder,
	disabled = false,
	options,
	index,
	idx,
}: SelectProps) {
	const { themeStatus } = useDarkMode();

	return (
		<>
			<Select
				style={{
					pointerEvents: disabled ? 'none' : 'all',width: '100%'
				}}
				notFoundContent={'Нет данных'}
				className={themeStatus === 'dark' ? 'dark-select' : ''}
				rootClassName={themeStatus === 'dark' ? 'dark-select' : ''}
				dropdownStyle={{ position: 'absolute', zIndex: '10000000' }}
				showSearch
				allowClear
				placeholder={placeholder}
				optionFilterProp='children'
				value={value ? value : null}
				onChange={(e) => {
					changeValues(`${name}Uuid`, e, index, idx);
				}}
				onSelect={(value, option) => {
					changeValues(`${name}Uuid`, value, index, idx);
				}}
				onSearch={(e) => changeSearchValue(name, e)}
				filterOption={false}
				options={options}
			/>
		</>
	);
}
