import { authHost, host } from '../axios';

export class AuthService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	// static async login(query: any): Promise<any[] | null> {
	// 	const { data } = await authHost.get(`auth/login`, {em});
	// 	return data;
	// }

	static async findOne(uuid: string, query?: any) {
		const { data } = await authHost.get(`auth/${uuid}?${query}`);
		return data;
	}

	static async forgotPassword(data: any) {
		const auth = await host.patch('auth/forgotPassword',data)
		return auth;
	}

	static async createNewPass(data: any) {
		const auth = await authHost.patch('auth/resetPassword',data)
		return auth;
	}

    static async changePassword(data: any) {
		const auth = await host.patch('auth/verifyContactResLink',data)
		return auth;
	}

	static async verify(data: any) {
		const otp = await authHost.post('auth/verifyOtp', data);
		const { access_token, refresh_token } = otp.data;

		return {
			access_token,
			refresh_token,
		};
	}
}
