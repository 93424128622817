import React from 'react';

export const TotalSum: React.FC<{
	text?: any;
	status: string[];
	className?: string | {};
}> = ({ text, status, className }) => {
	const formatNumber = (number: number) => {
		const parts = number.toFixed(2).split('.');
		const integerPart = parts[0];
		// const decimalPart = parts[1];
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${formattedIntegerPart}`;
	};

	return (
		<div className={`d-flex flex-column align-items-end ${className}`}>
			<div
				className={`rounded-2 d-flex align-items-center justify-content-center border-0 gap-3`}
				style={{ maxWidth: '100%' }}>
				<span className={`text-success fs-2 fw-bold text-nowrap`}>{`+ ${formatNumber(
					Number(text?.paid),
				)} AMD`}</span>
			</div>
			<div
				className={`rounded-2 d-flex align-items-center justify-content-center border-0 gap-3`}
				style={{ maxWidth: '100%' }}>
				<span className={`text-danger fs-2 fw-bold text-nowrap`}>{`${formatNumber(
					Number(text?.debt),
				)} AMD`}</span>
			</div>
		</div>
	);
};

export const Summary: React.FC<{
	number: number;
	status: string[];
	className?: string | {};
}> = ({ number, status, className }) => {
	const getColor = (number: number) => {
		if (number > 0) {
			return '';
		} else if (number < 0) {
			return 'danger';
		} else {
			return 'info';
		}
	};
	const symbolInPrice = number < 0 ? '' : '+';

	const color = getColor(number);
	const formatNumber = (number: number) => {
		const parts = number.toFixed(2).split('.');
		const integerPart = parts[0];
		const decimalPart = parts[1];
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${formattedIntegerPart}.${decimalPart}`;
	};

	return (
		<div className={`d-flex align-items-center ${className}`}>
			<div
				className={`rounded-2 d-flex align-items-center justify-content-center border-0 gap-3`}
				style={{ maxWidth: '100%' }}>
				<span
					style={{ color: number > 0 ? '#26BE00' : 'inherit' }}
					className={`text-${color} fs-2 fw-bold text-nowrap`}>{`${symbolInPrice}${formatNumber(
					Math.round(Number(number)),
				)} AMD`}</span>
			</div>
		</div>
	);
};
