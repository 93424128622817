import React, { FC, useState } from 'react';
import Button from '../../../../../../components/bootstrap/Button';
import Popovers from '../../../../../../components/bootstrap/Popovers';

interface IFullScreenItemProps {
	styledBtn: any;
}

const FullScreenItem: FC<IFullScreenItemProps> = ({ styledBtn }) => {
	const [isFullScreen, setIsFullScreen] = useState(false);

	const toggleFullScreen = () => {
	  if (!document.fullscreenElement) {
		document.documentElement.requestFullscreen().then(() => setIsFullScreen(true));
	  } else if (document.exitFullscreen) {
		document.exitFullscreen().then(() => setIsFullScreen(false));
	  }
	};

	return (
		<div className='col-auto'>
			<Popovers trigger='hover' desc='Полноэкранный режим'>
				<Button
					{...styledBtn}
					icon={isFullScreen ? 'FullscreenExit' : 'Fullscreen'}
					onClick={toggleFullScreen}
					aria-label='Toggle fullscreen'
				/>
			</Popovers>
		</div>
	);
};

export default FullScreenItem;
